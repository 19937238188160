
(function ($) {
    var pluginNew = this;
    pluginNew.settings = {};
    
    pluginNew.loadFacets = true;
    pluginNew.loadContent = true;
    pluginNew.groupClicked = false;
    pluginNew.megaMenuFilter = false;
    pluginNew.settings.groupName = "";
    pluginNew.settings.listUrl = "";
    pluginNew.settings.listType = "catcompletepeopleMenu";


    $.peopleListingMain = function (element, options) {
        /* defaults */
        var defaults = {
            pageNumber: "#pageNum",
            pageSize: "10",
            resultsontainer: "#peopleResult",
            itemId: "",
            filtersDiv: "#searchFilters",
            loaderDiv: "#divProcessingPeople",
            lastAlphaNav: '',
            pagingContainer: "#pagingContainerPeople",
            pageHeader: "#peopleListingHeader",
            contactFormUrl: ''

        };
     
        pluginNew.init();
    };

    /* add the pluginNew to the jQuery.fn object */
    $.fn.peopleListingMain = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('peopleListingMain')) {
                var pluginNew = new $.peopleListingMain(this, options);
                $(this).data('peopleListingMain', pluginNew);
             
            }
        });
    }

    $.autoSuggestMenu = function (element, options) {
        /* defaults */

        //alert("hiii");
        var $element = $(element), element = element;

        var defaults = {
            itemId: "",
            listUrl: "/api/custom/biolisting/getautosuggestresult",
            listType: "catcompletepeopleMenu",
            languageCode: $(element).attr('data-languagecode')
        };
        var $element = $(element), element = element;
        var pluginNew = this;
        pluginNew.settings = {}
        //"/BioListing/GetAutoSuggestResult"
        /* init function */
        pluginNew.init = function () {

            // clearAutoSuggestBox();
            pluginNew.settings = $.extend({}, defaults, options);
            if (pluginNew.settings.listType == "catcompletepeopleMenu") {
                if ($element.catcompletepeopleMenu) {
                    $element.catcompletepeopleMenu({
                        source: function (request, response) {
                            $.getJSON(pluginNew.settings.listUrl, { currentTime: $.now(), term: request.term, languageCode: pluginNew.settings.languageCode },
                                      response);
                        },
                        minLength: 2,
                        select: function (event, ui) {
                            window.location = decodeURIComponent(ui.item.PersonURL);
                        },
                        search: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        },
                        open: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        }
                    });
                }
            }

            if (pluginNew.settings.listType == "catcompleteGroupMenu") {
                if ($element.catcompleteGroupMenu) {
                    $element.catcompleteGroupMenu({
                        source: pluginNew.settings.listUrl,
                        minLength: 3,
                        select: function (event, ui) {
                            $(this).val(ui.item.FilterName);
                            $(this).attr("filter-IndexId", ui.item.FilterID);
                            $(this).attr("filter-IndexName", ui.item.FilterIndexName);
                            $(this).attr("filter-IndexValue", ui.item.FilterName);
                            $(this).attr("filter-IndexParentID", ui.item.FilterParentID);
                            return false;
                        },
                        search: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        },
                        open: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        }
                    });

                }
            }

            if (pluginNew.settings.listType == "completelistMenu") {
                if ($element.completelistMenu) {
                    $element.completelistMenu({
                        source: pluginNew.settings.listUrl,
                        minLength: 3,
                        select: function (event, ui) {
                            $(this).val(ui.item.FilterName);
                            $(this).attr("filter-IndexId", ui.item.FilterID);
                            $(this).attr("filter-IndexName", ui.item.FilterIndexName);
                            return false;
                        },
                        search: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        },
                        open: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        }
                    });

                }
            }
        }
        pluginNew.init();
    };

    /* add the pluginNew to the jQuery.fn object */
    $.fn.autoSuggestMenu = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('autoSuggest')) {
                var pluginNew = new $.autoSuggestMenu(this, options);
                $(this).data('autoSuggest', pluginNew);
            }
        });
    }

}(jQuery));



//aakash
$('#btnSearchMain2').click(function () {

    var searchString = '';
    var globalSearchString = '';
    var url = $(this).data("result-page") + "?";
    var query = [];

    query.push('param_sitecontentcategory=OUR PEOPLE');


    var currentGrpString = '';
    var filterGrp = ''
    var selectedValOffice = $('.allOfficeLocationddl2 select').val();
    var subCatInfoVal = '';
    var subCatTextVal = '';
    if (selectedValOffice != "") {
        filterGrp = 'Office'
        currentGrpString = selectedValOffice;
        var userTextPagesize = $("#allOfficeLocation2 option:selected").text();
        var subCatText = 'Office : ' + userTextPagesize;
        subCatInfoVal = "Office," + selectedValOffice;
        subCatTextVal = encodeURIComponent(subCatText);
    }

    if ($('.allPositionddl2 select').val()) {
        var selectedValPosition = $('.allPositionddl2 select').val();
        if (selectedValPosition != "") {
            var key = $('.allPositionddl2 select').find(':selected').data('category');
            query.push("designation_s=" + selectedValPosition);
        }
    }
    var practiceFieldID = '#sector-search2';
    if ($(practiceFieldID).val()) {
        var filterID = $(practiceFieldID).attr('filter-IndexId');
        var filterName = $(practiceFieldID).attr('filter-IndexName');
        var filterValue = $(practiceFieldID).attr('filter-IndexValue');
        var filterParentID = $(practiceFieldID).attr('filter-IndexParentID');
        var fieldtextValue =$(practiceFieldID).val();//.replace(/\&/g, '');

        if (fieldtextValue && filterValue && $.trim(fieldtextValue) != '' && $.trim(fieldtextValue) == $.trim(filterValue)) {
            var subCategoryTypeClickSubCat = '';
            var subCatInfoSubCat = '';
            var subCatTextSubCat = '';
            //encodeURIComponent
            switch (filterName) {
                case 'param_sectors_sm':
                    currentGrpString = filterID;
                    filterGrp = 'Sector';
                    subCategoryTypeClickSubCat = 'isSectorClick';
                    subCatInfoSubCat = 'Sector,' + filterID;
                    subCatTextSubCat = 'Sector : ' + filterValue.toUpperCase();
                    break;
                case 'param_practices_sm':
                    currentGrpString = filterID;
                    filterGrp = 'Service';
                    subCategoryTypeClickSubCat = 'isServiceClick';
                    subCatInfoSubCat = 'Service,' + filterID;
                    subCatTextSubCat = 'Service : ' + filterValue.toUpperCase();
                    break;
                case 'param_region_sm':
                    currentGrpString = filterID;
                    filterGrp = 'Region';
                    subCategoryTypeClickSubCat = 'isRegionClick';
                    subCatInfoSubCat = 'Region,' + filterID;
                    subCatTextSubCat = 'Region : ' + filterValue.toUpperCase();
                    break;

                case 'param_sub_sectors_sm':
                    currentGrpString = filterParentID;
                    filterGrp = 'Sector';
                    subCategoryTypeClickSubCat = 'isSectorClick';
                    query.push("subSectorInfo=" + filterID + ',' + filterParentID);
                    query.push("subSectorText=" + encodeURIComponent(filterValue.toUpperCase()));
                    break;
                case 'param_sub_practices_sm':
                    currentGrpString = filterParentID;
                    filterGrp = 'Service';
                    subCategoryTypeClickSubCat = 'isServiceClick';
                    query.push("subServiceInfo=" + filterID + ',' + filterParentID);
                    query.push("subServiceText=" + encodeURIComponent(filterValue.toUpperCase()));
                    break;
                case 'param_sub_region_sm':
                    currentGrpString = filterParentID;
                    filterGrp = 'Region';
                    subCategoryTypeClickSubCat = 'isRegionClick';
                    query.push("subRegionInfo=" + filterID + ',' + filterParentID);
                    query.push("subRegionText=" + encodeURIComponent(filterValue.toUpperCase()));
                    break;
            }

            if (subCategoryTypeClickSubCat) {
                query.push("subCategoryTypeClick=" + subCategoryTypeClickSubCat);
            }

            if (subCatInfoSubCat) {
                if (subCatInfoVal) {
                    subCatInfoVal = subCatInfoVal + ',' + subCatInfoSubCat;
                }
                else {
                    subCatInfoVal = subCatInfoSubCat;
                }
            }
            if (subCatTextSubCat) {
                if (subCatTextVal) {
                    subCatTextVal = subCatTextVal + ',' + encodeURIComponent(subCatTextSubCat);
                }
                else {
                    subCatTextVal = encodeURIComponent(subCatTextSubCat);
                }
            }
        }
        else {

            if (fieldtextValue && $.trim(fieldtextValue) != '') {
                var myValLower = $.trim(fieldtextValue).toLowerCase();
                var defaultMyValLower = $.trim($(practiceFieldID).attr('defaultvalue')).toLowerCase();
                if (myValLower != defaultMyValLower) {
                    query.push("sectorServiceSearchString=" + encodeURIComponent(myValLower));
                }
            }
        }

    }
    if (subCatInfoVal) {
        query.push("subCatInfo=" + subCatInfoVal);
    }
    if (subCatTextVal) {
        query.push("subCatText=" + subCatTextVal);
    }
    if (currentGrpString && currentGrpString != '') {
        query.push("currentGroup=" + currentGrpString);
    }
    if (filterGrp && filterGrp != '') {
        query.push("filtergroup=" + filterGrp);
    }


    if ($('#people-search2') != null && $('#people-search2').val() != null && $('#people-search2').val().toLowerCase() != $('#people-search2').attr('defaultvalue').toLowerCase()) {
        searchString =encodeURIComponent($('#people-search2').val());//.replace(/\&/g, '');
    }

    if (searchString) {
        query.push("SearchString=" + searchString);
    }


    window.location = url + query.join("&");

    return false;

});

